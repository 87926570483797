import React from 'react';
import { Link } from 'react-router-dom';
import imgCv from '../images/img_cv.webp';
import cmptData from './competenceData';

function Competences() {
    return (
        <section className="container_competences" id="competences">
            <h2 className="title">Compétences</h2>
            <div className="container_items_cmpt">
                {cmptData.map((competence) => (
                    <div key={competence.id} className="div_items_cmpt">
                        <img src={`/iconeTechno/${competence.name}.svg`} className="icon_competences" alt={competence.name} />
                        <p className="txt_competence">{competence.texte}</p>
                    </div>
                ))}
            </div>
            {/*---------------------------------- CV -----------------------------------*/}

            <Link to={'https://drive.google.com/file/d/1jr4MsvNj8oNYsxWuZK6rxBFGPY0nBxQU/view?usp=sharing'} className="link_cv" target="_blank">
                <img src={imgCv} className="imgCv" alt="curriculum vitae" />
            </Link>
        </section>
    );
}

export default Competences;

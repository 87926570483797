import React from 'react';
import { Link } from 'react-router-dom';
import icoLinkedin from '../icones/linkedin.svg';
import icoMail from '../icones/mail.svg';
import icoMalt from '../icones/malt.svg';

function Accueil() {
    return (
        <section>
            {/*------------------------------------------------------ contact ---------------------------------------------------*/}
            <div id="contact" className="container_contact">
                <h2 className="title title_contact">Contact</h2>
                <div className="container_socialNetwork">
                    <Link to={'mailto:florianseymortier@gmail.com'} className="link_icon" target="_blank">
                        <img src={icoMail} className="icon" alt="icone mail" />
                    </Link>
                    <Link to={'https://www.malt.fr/profile/florianseymortier'} className="link_icon" target="_blank">
                        <img src={icoMalt} className="icon" alt="icone malt.fr" />
                    </Link>
                    <Link to={'https://www.linkedin.com/in/florian-seymortier-995683202/'} className="link_icon" target="_blank">
                        <img src={icoLinkedin} className="icon" alt="icone Linkedin" />
                    </Link>
                    <Link to={'https://github.com/Fseymortier'} className="link_icon" target="_blank">
                        <img src="/iconeTechno/github.svg" className="icon" alt="icone Github" />
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Accueil;

import React from "react";
import Nav from "./component/nav";
import Presentation from "./component/presentation";
import Competences from "./component/competences";
import Projets from "./component/projets";
import Contact from "./component/contact";
import "./css/main.css";

function App() {
    return (
        <main>
            <Nav />
            <Presentation />
            <Competences />
            <Projets />
            <Contact />
        </main>
    );
}
export default App;

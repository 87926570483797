const projetsData = [
    {
        id: 9,
        name: 'l-ourse',
        titre: "Refonte générale du site de l'association de L\'ourse",
        description:
            "Le site ayant été développé il y a longtemps, une proposition de refonte a été demandée. Ainsi que la modification de certaines fonctionnalités, comme l'ajout d'actualité qui était permis à tout utilisateur connecté ou non, ayant les droits administrateurs ou non.",
        liens: [{ name: 'Lien du projet', url: 'https://l-ourse.fseymortier.fr/' }],
        techno: ['PHP', 'HTML', 'CSS'],
    },{
        id: 8,
        name: 'spai',
        titre: "Refonte et optimisation du site web du concessionnaire agricole SPAI",
        description:
            "La première version du site comportant beaucoup de bugs, j'ai recréé le site sur la base du site existant, l'intégralité du site. Tout en corrigeant et améliorant les bugs et fonctionnalités du site. Ainsi que son optimisation.",
        liens: [{ name: 'Lien du projet', url: 'https://spai.fseymortier.fr/' }],
        techno: ['PHP', 'JavaScript', 'HTML', 'CSS'],
    },
    {
        id: 7,
        name: 'konure',
        titre: "Création du site web pour l'agence digital Konure",
        description:
            "Dans ce projet, j'ai créé l'intégralité du site web pour l'agence digital KONURE. À partir des maquettes qui m'ont été fournies. Je l'ai créé avec le CMS WordPress et le plugin Elementor Pro.",
        liens: [{ name: 'Lien du projet', url: 'https://konure.fr/' }],
        techno: ['Wordpress', 'Elementor Pro'],
    },
    {
        id: 6,
        name: 'network',
        titre: "Créer un réseau social d'entreprise",
        description:
            "Ce projet était une mini Application, en ReactJS et NodeJS. J'ai dû créer et configurer le serveur NodeJS et l'API. Gérer la base de données et l'interface et les fonctionnalités coté client.",
        liens: [{ name: 'Lien du projet', url: 'https://groupomania.fseymortier.fr/' }],
        techno: ['NodeJS', 'ReactJS', 'Express', 'Axios', 'MYSQL', 'HTML', 'CSS'],
    },
    {
        id: 5,
        name: 'api',
        titre: 'Construire une API sécurisée',
        description:
            "À partir d'un projet Angular donné, il fallait que créer et configurer l'API et le serveur NodeJS",
        liens: [{ name: 'Lien du projet', url: 'https://hot-takes.fseymortier.fr/' }],
        techno: ['NodeJS', 'Express', 'MongoDB'],
    },
    {
        id: 4,
        name: 'e-commerce',
        titre: 'Construire un site e-commerce en JavaScript',
        description:
            "À partir de l'API fournie, il fallait récupérer les données de celle-ci. Et créer en JavaScript l'interface utilisateur.",
        liens: [{ name: 'Lien du projet', url: 'https://kanap.fseymortier.fr/' }],
        techno: ['JavaScript', 'HTML', 'CSS'],
    },
    {
        id: 3,
        name: 'optimisation',
        titre: 'Optimiser un site web existant',
        description:
            "Ce projet consistait à optimiser le site donné. Optimiser le contraste, images, bonnes pratiques... Puis de rendre un rapport de l'optimisation effectuée et l'audit SEO.",
        liens: [
            {
                name: 'Rapport optimisation',
                url: 'https://drive.google.com/file/d/14plWU9evh6x2fkkvbM2hgEZ34yZDTUov/view?usp=sharing',
            },
            {
                name: 'Audit SEO',
                url: 'https://drive.google.com/file/d/12vsqUet5FnV_CHGX-pnPis1e_Pn_CKqa/view?usp=sharing',
            },
            { name: 'Avant optimisation', url: 'https://lachouetteb.fseymortier.fr' },
            { name: 'Après optimisation', url: 'https://lachouettea.fseymortier.fr' },
        ],
        techno: ['HTML', 'CSS'],
    },
    {
        id: 2,
        name: 'ohmyfood',
        titre: 'Dynamiser une page web avec des animations CSS',
        description:
            "Sur ce projet, il fallait reproduire le design à l'identique des maquettes fournies. Et y ajouter les animations demandées.",
        liens: [{ name: 'Lien du projet', url: 'https://ohmyfood.fseymortier.fr/' }],
        techno: ['HTML', 'CSS'],
    },
    {
        id: 1,
        name: 'booki',
        titre: 'Implémenter une maquette en site web avec HTML & CSS',
        description:
            "Sur ce projet, il fallait reproduire le design à l'identique des maquettes fournies.",
        liens: [{ name: 'Lien du projet', url: 'https://booki.fseymortier.fr/' }],
        techno: ['HTML', 'CSS'],
    },
];
export default projetsData;

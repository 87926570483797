const cmptData = [
    {
        id: 1,
        name: 'html5',
        texte: 'HTML 5',
        image: '/iconeTechno/'
    },
    {
        id: 2,
        name: 'css3',
        texte: 'CSS 3',
        image: '/iconeTechno/'
    },
    {
        id: 3,
        name: 'js',
        texte: 'JavaScript',
        image: '/iconeTechno/'
    },
    {
        id: 4,
        name: 'php',
        texte: 'PHP',
        image: '/iconeTechno/'
    },
    {
        id: 5,
        name: 'react',
        texte: 'React.js',
        image: '/iconeTechno/'
    },
    {
        id: 6,
        name: 'node',
        texte: 'Node.js',
        image: '/iconeTechno/'
    },
    {
        id: 7,
        name: 'mysql',
        texte: 'MySQL',
        image: '/iconeTechno/'
    },
    {
        id: 8,
        name: 'mongodb',
        texte: 'MongoDB',
        image: '/iconeTechno/'
    },
    {
        id: 9,
        name: 'express',
        texte: 'Express',
        image: '/iconeTechno/'
    },
    {
        id: 10,
        name: 'sequelize',
        texte: 'Sequelize',
        image: '/iconeTechno/'
    },
    {
        id: 11,
        name: 'axios',
        texte: 'Axios',
        image: '/iconeTechno/'
    },
    {
        id: 12,
        name: 'git',
        texte: 'Git',
        image: '/iconeTechno/'
    },
    {
        id: 13,
        name: 'github',
        texte: 'GitHub',
        image: '/iconeTechno/'
    },
    {
        id: 14,
        name: 'filezilla',
        texte: 'FileZilla',
        image: '/iconeTechno/'
    },
    {
        id: 15,
        name: 'putty',
        texte: 'Putty',
        image: '/iconeTechno/'
    }
];
export default cmptData;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import IcoMenu from '../icones/menu.svg';
import logoFs from '../images/logo_FS.webp';

function Nav() {
    function scrollToSection(sectionId) {
        const section = document.getElementById(sectionId);
        if (window.innerWidth <= 675) {
            section.scrollIntoView({ behavior: 'smooth' });
            setNavVisible(false);
        } else {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const [isNavVisible, setNavVisible] = useState(true);
    const toggleNav = () => {
        setNavVisible(!isNavVisible);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 676) {
                setNavVisible(true);
            } else {
                setNavVisible(false);
            }
        };
        // Appeler handleResize lors du chargement de la page
        handleResize();
        // Ajouter un écouteur d'événements de redimensionnement
        window.addEventListener('resize', handleResize);
        // Nettoyer l'écouteur d'événements lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <nav>
            <img src={logoFs} className="logoNav" alt="logo FS" />
            <div>
                <Link className="mobileMenu" onClick={toggleNav}>
                    <img src={IcoMenu} className="iconNav" alt="icone menu" />
                </Link>
                <div className="div_nav" style={{ display: isNavVisible ? 'flex' : 'none' }}>
                    <span className="span_nav">
                        <Link onClick={() => scrollToSection('presentation')} className="nav_link">
                            Accueil
                        </Link>
                    </span>
                    <span className="span_nav borderNav">
                        <Link onClick={() => scrollToSection('competences')} className="nav_link">
                            Compétences
                        </Link>
                    </span>
                    <span className="span_nav borderNav">
                        <Link onClick={() => scrollToSection('projets')} className="nav_link">
                            Réalisations
                        </Link>
                    </span>
                    <span className="span_nav borderNav">
                        <Link onClick={() => scrollToSection('contact')} className="nav_link">
                            Contact
                        </Link>
                    </span>
                </div>
            </div>
        </nav>
    );
}
export default Nav;

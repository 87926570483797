import React from 'react';

function Presentation() {
    return (
        <section id="presentation">
            {/*------------------------------------------------------ presentation ---------------------------------------------------*/}
            <div className="container_presentation">
                <h1 className="title">Florian Seymortier Développeur Web</h1>
                <p className="presentation_text">
                    Bienvenue sur mon portfolio, n'hésitez pas à me contacter pour plus
                    d'informations.
                </p>
            </div>
        </section>
    );
}

export default Presentation;
